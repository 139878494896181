<template>
    <div class="Login-wrap ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                    <div class="login-form-wrap">
                        <div class="login-header">
                            <h3>Recover Password</h3>
                            <p>We will send a verification to your mail to reset your password.
                            </p>
                            <div v-if="isCompleted">
                                <p class="text-success">Reset completed. Please check your mailbox</p>
                            </div>
                        </div>
                        <div class="login-form" v-if="!isCompleted">
                            <div class="login-body">
                                <form class="form-wrap" @submit.prevent="resetPassowrd">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <input id="email" name="email" type="email" placeholder="Username/Email"
                                                    v-model="email" required>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <button class="btn style1 w-100 d-block">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <p class="mb-0">
                                                Back to
                                                <router-link class="link style1" to="/login">Sign In</router-link>
                                            </p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'RecoverPassword',
    data() {
        return {
            email: '',
            msg: null,
            isCompleted: false,
        };
    },
    methods: {
        async resetPassowrd() {
            try {
                const response = await axios.post('/Account/ResetPassword/' + this.email);
                this.msg = null;
                this.isCompleted = true;
                // this.$router.push('/login');
            } catch (error) {
                console.error(error);
                const { message } = error.response.data;

                // Handle the login error
                this.msg = message || 'Unexpected error, please try again later';
            }
        },
    },
}
</script>